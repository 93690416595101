<template>
  <!-- #default="{ hide }" -->
  <ChatSidebar
    :visible="visible"
    :user="user"
    class="d-flex justify-content-center align-items-center flex-column"
    @change="handleUpdateVisible"
    @shown="handleOnShow"
    @hidden="handleOnHide"
  >
    <div v-if="isLoading">
      <b-spinner
        size="2rem"
        type="grow"
        class="d-block mx-auto mt-3"
        variant="primary"
        label="Loading..."
      />
    </div>
    <ChatComponent :room="room || newValue" />
  </ChatSidebar>
</template>

<script>
import Service from '@/config/service-identifiers';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { CHAT_ACTIONS, CHAT_GETTERS } from '@/views/apps/chat/constants/chat-store-constants';
// import { BButtonClose, BSpinner } from 'bootstrap-vue';
// import ChatSidebar from '@/views/apps/chat/components/ChatSidebar.vue';
// import ChatComponent from '@/views/apps/chatv2/components/chatComponent.vue';

export default {
  name: 'UserChatSidebar',
  
  components: {
    ChatSidebar: () => import('@/views/apps/chat/components/ChatSidebar.vue' /* webpackChunkName: "ChatSidebar" */),
    ChatComponent: () => import('@/views/apps/chatv2/components/chatComponent.vue' /* webpackChunkName: "ChatComponent" */),
    // BButtonClose
    // BSpinner,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      isLoading: false,
      fetchedUser: null,
      newValue: null,
    };
  },
  computed: {
    visible() {
      return this.$store.getters[CHAT_GETTERS.isIndividualChatVisible];
    },
    user() {
      return this.$store.getters[CHAT_GETTERS.individualChatUser];
    },
    room() {
      return this.$store.getters[CHAT_GETTERS.room];
    },
    username() {
      return this.$store.getters[CHAT_GETTERS.individualChatUsername];
    },
    usernameFromQuery() {
      // console.log('Compute username from query...');
      console.log('this.$route.query', this.$route.query);
      // console.log("this.$route.query['chat-user']", this.$route.query['chat-user']);
      return this.$route.query['chat-user'];
    },
    computedUser() {
      return this.user || this.fetchedUser;
    },
  },
  watch: {
    // async usernameFromQuery() {
    //   console.log('Watching username from query...');
    //   return this.$route.query['chat-user'];
    // },
  },
  async created() {
    //console.log('UserChatSidebar Created!');
    this.newValue = null;
    const chatUsername = this.usernameFromQuery;
    if (chatUsername) {
      console.log('Getting chat messages from member with username: ', chatUsername);
      const response = await this.$store.dispatch('getItems', {
            itemType: 'communityMembers',
            storedKey: "chat11",
            page: 1,
            forceAPICall: true,
            perPage: 15,
            requestConfig: {
              username: chatUsername,
              getMinimalData: 1
            },
          });
      this.newValue = `${this.$store.getters.currentCollective.slug}-${(response.data[0].uniqueNumber + this.$store.getters.loggedMember.uniqueNumber).toString()}`;
      this.$store.dispatch(CHAT_ACTIONS.openIndividualChat, { 
        user: this.user,
        username: chatUsername 
      });
      this.handleOnShow();
    }
    //console.log('this.user', this.user);
  },
  methods: {
    async handleOnShow() {
      //console.log('Handle on show...');
      const chatUsername = this.usernameFromQuery;
      //console.log('chatUsername', chatUsername);
      if(chatUsername){
        const response = await this.$store.dispatch('getItems', {
              itemType: 'communityMembers',
              storedKey: "chat11",
              page: 1,
              forceAPICall: true,
              perPage: 15,
              requestConfig: {
                username: chatUsername,
                getMinimalData: 1,
              },
            });
        this.newValue = `${this.$store.getters.currentCollective.slug}-${(response.data[0].uniqueNumber + this.$store.getters.loggedMember.uniqueNumber).toString()}`;

        this.$store.dispatch(CHAT_ACTIONS.openIndividualChat, { 
          user: this.user,
          username: chatUsername 
        });
        this.fetchedUser = null;

        if (this.user) {
          //console.log('this.user', this.user);
          this.updateQuery(this.user.username);
          return;
        }
        if (this.username) {
          //console.log('this.username', this.username);
          this.updateQuery(this.username);
          await this.fetchUser(this.username);
        }
      }
    },
    handleOnHide() {
      //console.log('Handle on hide...');
      const query = { ...this.$route.query };
      delete query['chat-user'];
      this.$router.replace({ query, params: { savePosition: true } });
    },
    updateQuery(username) {
      //console.log('Updating query...');
      if (this.$route.query['chat-user'] !== username) {
        const query = { ...this.$route.query, 'chat-user': username };
        this.$router.replace({ query, params: { savePosition: true } });
      }
    },
    async fetchUser(username) {
      //console.log('Fetching user...');
      this.isLoading = true;
      try {
        // TODO: use member store
        const response = await this.$store.$service[Service.BackendClient]
          .get('/communityMembers', {
            params: {
              communityKey: this.$store.getters.currentCollective.key,
              username,
              getMinimalData: 1,
            },
          });

        const user = response.data.data[0];
        //console.log('fetchedUser:', user);
        if (!user) {
          this.notifyError(this.$t('chat.error-messages.user-not-found', [username]));
          this.$emit('update:visible', false);
          return;
        }

        this.fetchedUser = user;
      } catch (error) {
        this.notifyError(this.$t('chat.error-messages.user-failed', [username]));
        this.$emit('update:visible', false);
      }
      this.isLoading = false;
    },
    handleUpdateVisible(isVisible) {
      //console.log('Handle update visible...');
      if (!isVisible) {
        this.$store.dispatch(CHAT_ACTIONS.closeIndividualChat);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-close {
  position: absolute;
  right: 22px;
  top: 25px;
}
</style>
